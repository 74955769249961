.template-container {
    // thin border
    border: 1px solid #ddd;
    border-radius: var(--bs-border-radius);

    // required to allow draggables to be positioned relatively
    position: relative;


    > * {
        // don't force children to be rows
        display: inline-flex;
        // allow children to be placed anywhere in the container
        position: relative;
    }

}