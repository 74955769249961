/* make the customizations */
// modify the primary color
$primary: #9000ff;
$secondary: #69727c;

$body-bg: #f7f7f7;

// // change --bs-border-radius to 0
// $border-radius: 0rem;
// // make all bootstrap components have a border radius of 0
// $enable-rounded: false;


/* import bootstrap to set changes */
@import "~bootstrap/scss/bootstrap";


@import "spacing";

.fixed-button {
    position: fixed;
    bottom: 0;
    right: 0;
    margin: 1rem;
    z-index: 1000;
}
  
  
.glisten {
    // Copilot says mask works in Chrome, Safari, and Opera.
    -webkit-mask: linear-gradient(-110deg,#000 30%,rgba(0, 0, 0, 0.70),#000 70%) right/300% 10%;
    animation: glisten 6s infinite;
}
  
@keyframes glisten {
    100% {-webkit-mask-position:left}  
}

.scale-up-on-focus {
    transition: transform 0.1s;
    transform-origin: 50% 50%;
    transform: scale(1);
    &:focus {
        transform: scale(1.1);
    }
}


.max-z {
    z-index: 99999999999999;
}

.cursor-pointer {
    * {
        cursor: pointer;
    }
    cursor: pointer;
}