// import bootstrap button styles

.file-upload {

    margin: auto;

    &__button {
        display: block;
        width: 100%;
    }

    &__input {
        display: none;

    }

}