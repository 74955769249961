@each $key, $value in $spacers {
  .space-children-#{$key} {
    > *:not(:last-child) {
      margin-bottom: $value;
    }

    > *:last-child {
      margin-bottom: 0;
    }
  }
}
